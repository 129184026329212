import website from '@/config/website'
import { userStore } from '@/store/user'
export const tableOption = {
    border: true,
    stripe: true,
    searchMenuSpan: 6,
    align: 'center',
    // menu:false,
    height: 'auto',
    fit: true,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    searchLabelWidth: 80,
    column: [{
        label: '策略名称',
        prop: 'monitorName',
    },{
        label: '是否生效',
        prop: 'enableSwitch',
    },{
        label: '最后修改人',
        prop: 'updateBy',
    },{
        label: '创建时间',
        prop: 'createTime',
        width: 170,
    },{
        label: '最后修改时间',
        prop: 'updateTime',
        width: 170,
    }
],
}
export const tableOptionWallet = {
    border: true,
    stripe: true,
    searchMenuSpan: 6,
    align: 'center',
    menu:false,
    fit: true,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    searchLabelWidth: 80,
    // selection: true,selectionClassName:'请选泽',
    column: [{
        label: '商户',
        prop: 'tenantId',
        type: 'select',
        cascader: ['appId'],
        dicUrl: `${website.urls}/admin/tenant/page`,
        dicFormatter: (res)=>{
            return res.records
        },
        props: {
            value:'id',
            label:'name'
        },
        dicQuery: {current:1,size: 1000,tenantId:1},
        search: userStore().getIsPlatform,
        isPlatform:1,//1是平台
    },{
        label: 'app名称',
        prop: 'appId',
        type: 'select',
        dicUrl: `${website.urls}/admin/userappinfo/page?tenantId={{key}}`,
        dicFormatter: (res)=>{
            return res.records
        },
        props: {
          label: 'appName',
          value: 'appId'
        },
        dicQuery: {current:1,size: 1000,tenantId:1},
        search: true
    },{
        label: '钱包类型',
        prop: 'walletType',
        search:true,
        type: 'radio',
        dicData: [
            { label: '出入金热钱包',  value: 1 }, 
            { label: '链费钱包', value: 2 },
            { label: '最终冷钱包', value: 3 },
        ],
    },{
        label: '链',
        prop: 'chain',
    },{
        label: '币种',
        prop: 'coin',
    },{
        label: '钱包名称',
        prop: 'walletName',
    },{
        label: '钱包名称',
        prop: 'walletName',
    },{
        width:170,
        label: '创建时间',
        prop: 'createTime'
    },{
        label: '请选择',
        prop: 'checkedFun'
    },
],
}
<template>
    <div class="earlyWarningRules">
        <basicContainer>
            <avue-crud ref="crud" :page="page" :data="page.list"
                 :option="tableOption1"  :table-loading="listLoading"
                @size-change="sizeChange" @current-change="currentChange">
                <template #createTime="scope">
                    {{turnTimeFun(scope.row.createTime)}}
                </template>
                <template #updateTime="scope">
                    {{turnTimeFun(scope.row.updateTime)}}
                </template>
                <template #menu="scope">
                    <el-button @click="lookView(scope.row)" text type='primary'>查看</el-button>
                    <el-button @click="updateView(scope.row)" text type='primary'>修改</el-button>
                </template>
                <template #enableSwitch="scope">
                    <el-switch v-model="scope.row.enableSwitch" 
                        active-text="生效" inactive-text="失效" :active-value="1"
                        :inactive-value="0" @click="changeValue(scope.row)" />
                </template>
            </avue-crud>
            <el-dialog :title="isShow?'详情':'修改'" v-model="dialogVisible" width="50%">
                    <el-form ref="formRef" :model="form" label-width="80px" label-position="top"  :rules="rules">
                        <el-form-item label="名称" prop="monitorName" :rules="[{
                            required: true,
                            message: '请填写',
                            trigger: 'blur',
                        },]">
                            <el-input v-model="form.monitorName" :disabled="isShow"></el-input>
                        </el-form-item>
                        <el-form-item label="预警类型" v-loading="loadingList">
                            <template v-if="form.monitorCode === 'WALLET_ADDRESS_CHANGE'">
                                <div class="single_" v-for="item in lookViewBody" :key="item.id">
                                    <template v-if="item.riskLevel === 3">
                                        <div class="div_ big_">高风险</div><div>{{item.alarmType === '1'?'出入金热钱包':'冷钱包'}}</div> 
                                    </template>
                                    <template v-else-if="item.riskLevel === 2">
                                        <div class=" div_ midden_">中风险</div><div>{{item.alarmType === '1'?'出入金热钱包':'冷钱包'}}</div>
                                    </template>
                                    <template v-else>
                                        <div class=" div_ low_">低风险</div><div>{{item.alarmType === '1'?'出入金热钱包':'冷钱包'}}</div>
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="form.monitorCode === 'LARGE_WITHDRAW_COIN' || form.monitorCode === 'LARGE_DEPOSIT_COIN'">
                                <el-collapse accordion style="width:100%">
                                    <el-collapse-item v-for="(item,index) in lookViewBody" :key="index">
                                        <template #title>
                                            <div style="margin-right: 5px;">币种：</div>
                                            <el-select v-model="item.chain" placeholder="请选泽" :disabled="isShow" @change="screenData">
                                                <el-option v-for="item in chain_array" :key="item.key" :label="item.label"  :value="item.value" :disabled="item.status !== 1">
                                                    <span style="float: left">{{ item.label }}</span>
                                                    <span style=" float: right;  color: var(--el-text-color-secondary); font-size: 13px; " v-if="item.status !== 1">
                                                        {{ item.status === 2?'已有':'未启用' }}
                                                    </span >
                                                </el-option>
                                            </el-select>
                                            <el-button icon="el-icon-delete" @click="remove_item(item)" style="margin-left: 10px;" circle  v-if='!isShow'></el-button>
                                        </template>
                                        <div class="single_" style="margin-top: 10px;">
                                            <div class="div_ low_">低风险</div><span>数量</span>
                                                <avue-input-number v-model="item.lowValue" placeholder="请输入"  :min="0" :precision="8" :disabled="isShow"></avue-input-number>
                                        </div>
                                        <div class=" single_ ">
                                            <div class=" div_ midden_">中风险</div><span>数量</span>
                                            <avue-input-number v-model="item.middenValue" placeholder="请输入"  :min="0" :precision="8" :disabled="isShow"></avue-input-number>
                                        </div>
                                        <div class=" single_ ">
                                            <div class="div_ big_">高风险</div><span>数量</span>
                                            <avue-input-number v-model="item.bigValue" placeholder="请输入"  :min="0" :precision="8" :disabled="isShow"></avue-input-number>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                                <el-button icon="el-icon-plus" @click="add_items" style="margin-top: 10px;width: 300px;" v-if='!isShow'>添加</el-button>
                            </template>
                            <template v-else-if="form.monitorCode === 'WITHDRAW_COIN_AUDIT'">
                                <div class="single_" v-for="item in lookViewBody" :key="item.id">
                                    <template v-if="item.riskLevel === 3">
                                        <div class="div_ big_">高风险</div>
                                        <div>审核时间大于
                                            <avue-input-number v-model="item.timeValue" :min="0" :step="1" placeholder="请输入" :disabled="isShow"></avue-input-number>
                                            <span style="margin-left: 5px;">分钟</span>
                                        </div> 
                                    </template>
                                    <template v-else-if="item.riskLevel === 2">
                                        <div class="div_ midden_">中风险</div><div>审核时间大于
                                            <avue-input-number v-model="item.timeValue" :min="0" :step="1" placeholder="请输入" :disabled="isShow"></avue-input-number>
                                                <span style="margin-left: 5px;">分钟</span>
                                        </div> 
                                    </template>
                                    <template v-else>
                                        <div class="div_ low_">低风险</div><div>审核时间大于
                                            <avue-input-number v-model="item.timeValue" :min="0" :step="1" placeholder="请输入" :disabled="isShow"></avue-input-number>
                                                <span style="margin-left: 5px;">分钟</span>
                                        </div> 
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="form.monitorCode === 'RECHARGED_COIN_NOT_COLLECTED'">
                                <div class="single_" v-for="item in lookViewBody" :key="item.id">
                                    <template v-if="item.riskLevel === 3">
                                        <template v-if="item.amountValue === '0'">
                                            <div class="div_ big_">高风险</div>
                                            <div>未归集时间大于 
                                                <!-- <el-input v-model="item.timeValue" placeholder="请输入" :disabled="isShow">
                                                <template #append>分钟</template></el-input> -->
                                                <avue-input-number v-model="item.timeValue" :min="0" :step="1" placeholder="请输入" :disabled="isShow"></avue-input-number>
                                                <span style="margin-left: 5px;">分钟</span>
                                            </div> 
                                        </template>
                                        <template v-else>
                                            <div class="div_ big_">高风险</div><div>充币金额折合 
                                                <avue-input-number v-model="item.amountValue" :min="0" :precision="8" placeholder="请输入"  :disabled="isShow"></avue-input-number>
                                                <span style="margin-left: 5px;">USDT</span>
                                                <span style="margin-left: 10px;">且归集时间大于</span>
                                                <avue-input-number v-model="item.timeValue" :min="0" :step="1" placeholder="请输入"  :disabled="isShow"></avue-input-number>
                                                <span style="margin-left: 5px;">分钟</span>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-else-if="item.riskLevel === 2">
                                        <div class="div_ midden_">中风险</div><div>未归集时间大于 
                                            <avue-input-number v-model="item.timeValue" :min="0" :step="1" placeholder="请输入" :disabled="isShow"></avue-input-number>
                                                <span style="margin-left: 5px;">分钟</span>
                                        </div> 
                                    </template>
                                    <template v-else>
                                        <div class="div_ low_">低风险</div><div>未归集时间大于 
                                            <avue-input-number v-model="item.timeValue" :min="0" :step="1" placeholder="请输入" :disabled="isShow"></avue-input-number>
                                                <span style="margin-left: 5px;">分钟</span>
                                        </div> 
                                    </template>
                                </div>
                            </template>
                            <template v-else-if="form.monitorCode === 'BLOCK_HEIGHT'">
                                <div class="single_" v-for="(item,index) in lookViewBody" :key="item.id">
                                    <div class="div_ big_">高风险</div>
                                    <el-select v-model="item.chain" placeholder="请选泽" :disabled="isShow" @change="screenData">
                                        <el-option v-for="item in chain_array" :key="item.key" :label="item.label"  :value="item.value" :disabled="item.status !== 1">
                                            <span style="float: left">{{ item.label }}</span>
                                            <span style=" float: right;  color: var(--el-text-color-secondary); font-size: 13px; " v-if="item.status !== 1">
                                                {{ item.status === 2?'已有':'未启用' }}
                                            </span >
                                        </el-option>
                                    </el-select>
                                    <avue-input-number v-model="item.timeValue" :min="0" :step="1" placeholder="请输入" :disabled="isShow"></avue-input-number>
                                        <span style="margin-left: 5px;">分钟</span>
                                    <el-button plain @click="removeData(index)" type="danger">删除</el-button>
                                </div>
                                <div class="single_" v-if='!isShow'>
                                    <el-button plain @click="addHeightFun">新增</el-button>
                                </div>
                            </template>
                            <template v-else-if="form.monitorCode === 'WATER_LINE'">
                                <div class="single_" v-for="(item,index) in lookViewBody" :key="item.id">
                                    <div class="div_ big_">高风险</div>
                                    <el-button plain @click="checkWallet(index)" v-if="!isShow">选择</el-button>
                                    <el-input v-model="item.alarmTypeDesc" placeholder="请选择钱包"   style="margin:0 5px;width: 100px" disabled ></el-input>
                                    <el-input v-model="item.chain" placeholder="请选择链" disabled style="width: 100px"></el-input>
                                    <el-input v-model="item.coin" placeholder="请选择币" style="margin:0 5px;width: 100px" disabled></el-input>
                                    <!-- <el-input v-model="item.amountValue" :disabled="isShow" ><template #append>金额</template></el-input> -->
                                    <avue-input-number v-model="item.amountValue" placeholder="请输入"  :min="0" :precision="8" :disabled="isShow"></avue-input-number>
                                    <span style="margin-left: 5px;">金额</span>
                                    <el-button plain @click="removeData(index)" type="danger">删除</el-button>
                                </div>
                                <div class="single_" v-if='!isShow'>
                                    <el-button plain @click="addHeightFun">新增</el-button>
                                </div>
                            </template>
                            <template v-else-if="form.monitorCode === 'BLOCK_HEIGHT_DIFFERENCE'">
                                <!-- <template v-slot='label'>
                                    预警类型 <el-button icon="el-icon-plus" size="mini" @click="add_items" style="margin-left: 10px;" v-if='!isShow'>添加</el-button>
                                </template> -->
                                <el-collapse accordion style="width:100%">
                                    <el-collapse-item v-for="(item,index) in lookViewBody" :key="index">
                                        <template #title>
                                            <div style="margin-right: 5px;">链：</div>
                                            <el-select v-model="item.chain" placeholder="请选泽" :disabled="isShow" @change="screenData">
                                                <el-option v-for="item in chain_array" :key="item.key" :label="item.label"  :value="item.value" :disabled="item.status !== 1">
                                                    <span style="float: left">{{ item.label }}</span>
                                                    <span style=" float: right;  color: var(--el-text-color-secondary); font-size: 13px; " v-if="item.status !== 1">
                                                        {{ item.status === 2?'已有':'未启用' }}
                                                    </span >
                                                </el-option>
                                            </el-select>
                                            <el-button icon="el-icon-delete" @click="remove_item(item)" style="margin-left: 10px;" circle  v-if='!isShow'></el-button>
                                        </template>
                                        <div class="single_" style="margin-top: 10px;">
                                            <div class="div_ low_">低风险</div><span>数量</span>
                                                <avue-input-number v-model="item.lowValue" placeholder="请输入"  :min="0" :precision="8" :disabled="isShow"></avue-input-number>
                                        </div>
                                        <div class=" single_ ">
                                            <div class=" div_ midden_">中风险</div><span>数量</span>
                                            <avue-input-number v-model="item.middenValue" placeholder="请输入"  :min="0" :precision="8" :disabled="isShow"></avue-input-number>
                                        </div>
                                        <div class=" single_ ">
                                            <div class="div_ big_">高风险</div><span>数量</span>
                                            <avue-input-number v-model="item.bigValue" placeholder="请输入"  :min="0" :precision="8" :disabled="isShow"></avue-input-number>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                                <el-button icon="el-icon-plus" @click="add_items" style="margin-top: 10px;width: 300px;" v-if='!isShow'>添加</el-button>
                            </template>
                        </el-form-item>
                        <el-form-item label="是否生效" prop="enableSwitch">
                            <el-switch v-model="form.enableSwitch"  :disabled="isShow"
                            active-text="生效" inactive-text="失效" :active-value="1"
                            :inactive-value="0" />
                        </el-form-item>
                    </el-form>
                    <el-dialog v-model="innerVisible" width="70%" title="选择钱包" append-to-body >
                        <avue-crud ref="cruds"  :data="tableList" @search-change="searchChange"
                            :option="tableOption2"  :table-loading="listLoading1">
                            <template #checkedFun="scope">
                                <el-button v-if="isBoolShow(scope.row)" disabled>已有</el-button>
                                <el-button @click="changeItemValue(scope.row)" v-else>选中</el-button>
                            </template>
                        </avue-crud>
                    </el-dialog>

                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="submitData('form')" v-if='!isShow'>提交</el-button>
                    </span>

                </template>
            </el-dialog>
        </basicContainer>
    </div>
</template>
<script setup>
import { ref } from 'vue'
    import Api_ from '@/api/index'
    // import { monitorAlarm,monitorAlarmEdit,monitorAlarmDetail } from '@/api/monitor-early-warning/early-warning-rules.js'
    import { tableOption,tableOptionWallet } from "@/const/crud/monitor-early-warning/early-warning-rules.js";
    // import pageMixin from '@/mixins/page.js'
    // import moment from 'moment'
    import basicContainer from '../compontents/main.vue'
    import { ElNotification } from 'element-plus'
    import { turnTimeFun } from '@/utils/util.js'

    // const tableData = ref([]);
    const tableOption1 = ref(tableOption);
    const isShow = ref(false);
    const form = ref({});
    const dialogVisible = ref(false);
    // const lowTimes = ref(60);
    // const middenTimes = ref(60);
    // const bigTimes = ref(60);
    const rules = {
        monitorName: [
            { required: true, message: '请输入', trigger: 'blur' },
        ],
        enableSwitch: [
            { required: true, message: '请选择', trigger: 'change' }
        ]
    };
    const lookViewBody = ref([])
    const chain_array = ref([])

    const formRef = ref(null)
    const innerVisible = ref(false)
    const listLoading1 = ref(false)
    const tableOption2 = ref(null);tableOption2.value = tableOptionWallet
    const tableList = ref([])
    const indexs = ref(0)
    const loadingList = ref(false)
    

    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['monitorAlarm'])
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj

    const add_items = ()=>{
        lookViewBody.value.push({
            chain:'',lowTimes:'',middenTimes:'',bigTimes:'',
        })
    }
    const remove_item = (item)=>{
        let index = lookViewBody.value.indexOf(item);
        lookViewBody.value.splice(index, 1);  
    }
    const isBoolShow = (item)=>{
        let bool_ = false
        lookViewBody.value.forEach(items =>{
            if(items.alarmType === item.walletInfoId && items.chain === item.chain && items.coin === item.coin){
                bool_ = true
            }
        })
        return bool_
    }
    const changeItemValue  = (items)=>{
        innerVisible.value = false
        let lookViewBodys = lookViewBody.value;
        lookViewBodys[indexs.value].alarmType = items.walletInfoId
        lookViewBodys[indexs.value].chain = items.chain
        lookViewBodys[indexs.value].coin = items.coin
        lookViewBodys[indexs.value].alarmTypeDesc = items.walletName
        lookViewBody.value = lookViewBodys
    }
    const addHeightFun = ()=>{
        if(form.value.monitorCode === 'WATER_LINE'){
            lookViewBody.value.push({
                riskLevel: 3,monitorCode:'WATER_LINE'
            })
        }else{
            lookViewBody.value.push({
                riskLevel: 3,chain:'',monitorCode:'BLOCK_HEIGHT',timeValue: ''
            })
        }
    }
    const searchChange = (form,done)=>{//
        listLoading1.value = true
        Api_.getAssetswalletinfoList(form).then(res=>{
            listLoading1.value = false
            if(res.code === 0){
                tableList.value = res.data.records
            }
        })
        done()
    }
    const checkWallet = (index)=>{//点击选择钱包
        innerVisible.value = true
        indexs.value = index
    }
    const submitData = ()=>{
        if (!formRef.value) return
        formRef.value.validate(async (e) => {
            if(e){
                if(form.value.monitorCode === 'WALLET_ADDRESS_CHANGE'){
                    questObj(form.value)
                }else if(form.value.monitorCode === 'RECHARGED_COIN_NOT_COLLECTED' || form.value.monitorCode === 'WITHDRAW_COIN_AUDIT'){
                    let obj = form.value;
                    let bool_ = lookViewBody.value.some(ele=>{return !ele.timeValue })
                    if(bool_){
                        ElNotification({
                            message: form.value.monitorCode === 'WITHDRAW_COIN_AUDIT'?'审核时间不能为空':'归集时间设置有问题',
                            type: 'error',
                        })
                    }else{
                        for(let item__ of lookViewBody.value){
                            if(item__.timeValue){
                                item__.timeValue = parseInt(item__.timeValue)
                            }
                        }
                        obj.editMonitorAlarmDetailReqList = lookViewBody.value
                        questObj(obj)
                    }
                }else if(form.value.monitorCode === 'BLOCK_HEIGHT'){
                    let obj = form.value;
                    let bool_ = lookViewBody.value.some(ele=>{return !ele.timeValue })
                    if(bool_){
                        ElNotification({
                            message: '时间设置有问题',
                            type: 'error',
                        })
                    }else{
                        for(let item__ of lookViewBody.value){
                            if(item__.timeValue){
                                item__.timeValue = parseInt(item__.timeValue)
                            }
                        }
                        obj.editMonitorAlarmDetailReqList = lookViewBody.value
                        questObj(obj)
                    }
                }else if(form.value.monitorCode === 'WATER_LINE'){
                    let obj = form.value;
                    let bool_ = lookViewBody.value.some(ele=>{return !ele.amountValue })
                    if(bool_){
                        ElNotification({
                            message: '金额设置有问题',
                            type: 'error',
                        })
                    }else{
                        obj.editMonitorAlarmDetailReqList = lookViewBody.value
                        questObj(obj)
                    }
                }else if(form.value.monitorCode === 'BLOCK_HEIGHT_DIFFERENCE'|| form.value.monitorCode === 'LARGE_WITHDRAW_COIN' || form.value.monitorCode === 'LARGE_DEPOSIT_COIN'){
                    const { enableSwitch,monitorCode,monitorName } = form.value
                    let obj = {enableSwitch,monitorCode,monitorName};
                    obj.editMonitorAlarmDetailReqList = []
                    for(const item of lookViewBody.value){
                        if(item.lowValue && item.middenValue && item.bigValue && item.chain){
                            obj.editMonitorAlarmDetailReqList = [...obj.editMonitorAlarmDetailReqList,{
                                riskLevel: 1,amountValue: item.lowValue,alarmType: item.chain
                            },{
                                riskLevel: 2,amountValue: item.middenValue,alarmType: item.chain
                            },{
                                riskLevel: 3,amountValue: item.bigValue,alarmType: item.chain
                            }]
                        }else{
                            ElNotification({
                                message: '不为空',
                                type: 'error',
                            })
                            return 0
                        }
                    }
                    questObj(obj)
                }
            }
        })
    }
    const questObj = async (obj_) =>{
        const data = await Api_.monitorAlarmEdit(obj_);
            if(data.code === 0){
                getList()
                dialogVisible.value = false;
                ElNotification({
                    message: '修改成功',
                    type: 'success',
                })
            }else{
                ElNotification({
                    message: data.msg,
                    type: 'error',
                })
            }
    }
    const removeData = (index)=>{
        lookViewBody.value.splice(index, 1);
        screenData()
    }
    const lookView = async (row)=>{
        dialogVisible.value = true;
        isShow.value = true;
        form.value = row
        loadingList.value = true
        const data = await Api_.monitorAlarmDetail(row.monitorCode);
        loadingList.value = false
        if(data.code === 0){
            if(row.monitorCode === 'BLOCK_HEIGHT_DIFFERENCE' || row.monitorCode === 'LARGE_WITHDRAW_COIN' || row.monitorCode === 'LARGE_DEPOSIT_COIN' ){
                lookViewBody.value = []
                data.data.map(item=>{
                    const str_ = ['','lowValue','middenValue','bigValue']
                    if(lookViewBody.value.some(items => items.chain === item.alarmType)){
                        for(let items_ of lookViewBody.value){
                            if(items_.chain === item.alarmType){
                                items_[str_[item.riskLevel]] = item.amountValue
                                return 0
                            }
                        }
                    }else{
                        let obj_ = {};obj_.chain = item.alarmType
                        obj_[str_[item.riskLevel]] = item.amountValue || 0
                        lookViewBody.value.push(obj_)
                    }
                })
            }else{
                lookViewBody.value = data.data
            }
        }else{
            ElNotification({
                message: data.msg,
                type: 'error',
            })
        }
    }
    const screenData =  () => {
        if(lookViewBody.value.length > 0){
            for (let i = 0; i < chain_array.value.length; i++) {  
                for (let j = 0; j < lookViewBody.value.length; j++) {  
                    if (chain_array.value[i].label === lookViewBody.value[j].chain) {  
                        chain_array.value[i].status = 2
                        break;
                    } else if(j === lookViewBody.value.length - 1 && chain_array.value[i].status === 2){
                        chain_array.value[i].status = 1
                    }
                }  
            }
        }
    }
    const updateView = async (row) =>{
        dialogVisible.value = true;
        isShow.value = false
        form.value = JSON.parse(JSON.stringify(row))
        loadingList.value = true
        const data = await Api_.monitorAlarmDetail(row.monitorCode);
        loadingList.value = false
        if(data.code === 0){
            if(row.monitorCode === 'LARGE_WITHDRAW_COIN' || row.monitorCode === 'LARGE_DEPOSIT_COIN' || row.monitorCode === 'BLOCK_HEIGHT_DIFFERENCE'){
                chain_array.value = []

                if(row.monitorCode === 'BLOCK_HEIGHT_DIFFERENCE'){
                    Api_.getCoinList({current:1,size:100}).then(res=>{
                        if(res.code === 0 && res.data.records.length > 0){
                            chain_array.value = res.data.records.map(item=>{
                                return {
                                    label: item.chain,
                                    value: item.chain,
                                    status: item.status,//0,未启用；1，已启用；2，已有数据
                                    key: item.chainId
                                }
                            })
                            screenData()
                        }
                    })
                }else{
                    Api_.getCurrencyList({current:1,size:20}).then(res=>{
                        if(res.code === 0 && res.data.records.length > 0){
                            chain_array.value = res.data.records.map(item=>{
                                return {
                                    label: item.chain+formatCoinData(item.protocol)+formatCoinData(item.coin),
                                    value: item.coin,
                                    status: item.status,//0,未启用；1，已启用；2，已有数据
                                    key: item.coinId
                                }
                            })
                            screenData()
                        }
                    })
                }
                lookViewBody.value = []
                data.data.map(item=>{
                    const str_ = ['','lowValue','middenValue','bigValue']
                    if(lookViewBody.value.some(items => items.chain === item.alarmType)){
                        for(let items_ of lookViewBody.value){
                            if(items_.chain === item.alarmType){
                                items_[str_[item.riskLevel]] = item.amountValue
                                return 0
                            }
                        }
                    }else{
                        let obj_ = {};obj_.chain = item.alarmType
                        obj_[str_[item.riskLevel]] = item.amountValue || 0
                        lookViewBody.value.push(obj_)
                    }
                })
            }else{
                lookViewBody.value = data.data;
            }
            if(row.monitorCode === 'BLOCK_HEIGHT'){

                chain_array.value = []
                Api_.getCoinList({current:1,size:100}).then(res=>{
                    if(res.code === 0 && res.data.records.length > 0){
                        chain_array.value = res.data.records.map(item=>{
                            return {
                                label: item.chain,
                                value: item.chain,
                                status: item.status,//0,未启用；1，已启用；2，已有数据
                                key: item.chainId
                            }
                        })
                        screenData()
                    }
                })
            }
        }else{
            ElNotification({
                message: data.msg,
                type: 'error',
            })
        }

    }
    // 拼接币种类型 /BTC  
    const formatCoinData = (value) => {
        return value ? `/${value}` : ''
    }
    const changeValue = async (e) =>{
        listLoading.value = true;
        const status = e.enableSwitch;
        const data = await Api_.monitorAlarmEditNew({monitorCode:e.monitorCode,enableSwitch:status});
        listLoading.value = false;
        if(data.code === 0){
            getList(1)
            ElNotification({
                message: '修改成功',
                type: 'success',
            })
        }else{
            ElNotification({
                message: data.msg,
                type: 'error',
            })
        }

    }
    getList(1)
</script>
<style scoped>
.single_{
    display: flex;
    margin-bottom: 5px;
    width: 100%;
}
.single_ .div_{
        margin-right: 10px;
        color: #fff;
        height: 30px;width: 80px;
        display: flex;align-items: center;justify-content: center;
    }
.single_ .low_{background: #93c6f8;}
.single_ .midden_{background: #f6cb8c;}
.single_ .big_{background: rgb(247, 144, 144);}
.single_ span{
        margin-right: 5px;
    }
.single_ .el-input{
        width: 200px;
    }
</style>